.work {
    margin: 100px 0px;
    .work-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        color: $white;

        h1 {
            margin-bottom: 2rem;
            font-size: 2.5rem;
            text-transform: uppercase;
            color: transparent;
            -webkit-text-stroke: .75px $white;
            -webkit-text-fill-color:transparent;
            position: relative;
            left: 100px !important;
            opacity: 0;
            transition: 1s;
        }

        .experience__details {
            display: flex;
            position: relative;

            nav {
                width: 20%;
                min-width: 250px;
                margin-right: 2rem;

                .experience-button-container {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: .5rem;
                    display: none;
                    opacity: 0;
                    transition: 1s;
                    position: relative;
                    top: 100px;
                }

                ul {
                    padding: 0px;
                    list-style: none;
                    opacity: 0;
                    transition: .3s;

                    .experience-list-item {
                        transition: .3s;
                        background: none;
                        border-right: 3px solid $primary;
                        border-top-left-radius: $border-radius;
                        border-bottom-left-radius: $border-radius;
                        background: linear-gradient(to left, $primary 50%,  transparent 50%) left;
                        background-size: 200%;
                        cursor: pointer;
                        opacity: .75;
                        
                        &.active {
                            background-position: right;
                            opacity: 1;
                        }

                        &:hover {
                            opacity: 1 !important;
                        }
                        
                        button {
                            padding: .5rem .75rem;
                            color: $white;
                            border: none;
                            outline: none;
                            background: transparent;
                            font-size: 1.25rem;
                        }
                    }
                }
            }

            .jobs-container {
                position: relative;
                top: 30px;
                transition: .5s;
                opacity: 0;

                &.visible {
                    top: 0px;
                    opacity: 1;
                }

                .job-container {
                    position: absolute;
                    opacity: 0;
                    visibility: hidden;
                    background: $background;
                    padding: 1.5rem;
                    border-radius: $border-radius;
                    border: 1px solid $white;

                    &.bumper {
                        visibility: hidden;
                        position: relative !important;
                        top: 0;
                    }

                    &.active {
                        visibility: visible;
                        opacity: 1;
                    }

                    .experience {
                        font-size: 1.75rem;
                        font-weight: bold;
                        margin-bottom: .5remrem;
                        display: flex;

                        .highlight-container {
                            display: flex;
                            margin-left: 5px;
                            color: $primary !important;
                            position: relative;
                            cursor: pointer;

                            .company {
                                a {
                                    margin-left: 3px;
                                    color: $primary !important;
                                    position: relative;
                                    cursor: pointer;
                                    text-decoration: none;
                                }
                            }

                            &:hover,
                            &:focus,
                            &:focus-within {
                                color: $primary !important;
                                border: none;
                                outline: none;
                            }

                            &:before{
                                content: '';
                                position: absolute;
                                bottom: -5px;
                                right: 0;
                                width: 0;
                                height: 3px;
                                background-color: $primary;
                                transition: width 0.5s cubic-bezier(0.25, 1, 0.5, 1);
                            }

                            &:hover:before,
                            &:focus:before,
                            &:focus-within:before {
                                left: 0;
                                right: auto;
                                width: 100%;
                            }
                        }
                    }

                    .date {
                        opacity: .75;
                        font-size: 1.25rem;
                    }

                    .description {
                        margin-top: 1rem;
                        font-size: 1.25rem;

                        a {
                            opacity: 1;
                            position: relative;
                            color: $primary;
                            text-decoration: none;
                            transition: .5s ease;
                            display: inline-block;
                            text-decoration: none;
                            font-size: 1.25rem;
                            margin-right: 0rem;
                            padding: 0rem;
            
                            &:hover,
                            &:focus,
                            &:focus-within {
                                color: $primary-d !important;
                                border: none;
                                outline: none;
                            }
            
                            &:before{
                                content: '';
                                position: absolute;
                                bottom: -2px;
                                right: 0;
                                width: 0;
                                height: 1.5px;
                                background-color: $primary-d;
                                transition: width 0.5s cubic-bezier(0.25, 1, 0.5, 1);
                            }
            
                            &:hover:before,
                            &:focus:before,
                            &:focus-within:before {
                                left: 0;
                                right: auto;
                                width: 100%;
                            }
                        }

                        ul {
                            margin-top: .5rem;
                            margin-bottom: 1rem;
                            padding-left: 0px;

                            li {
                                list-style: inside;
                                color: $primary;
                                margin-bottom: .5rem;

                                span {
                                    color: $white;
                                    font-size: 1.25rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .work {
        .work-content {
            h1 {
                // font-size: 2rem;
            }

            .experience__details {
                flex-direction: column;
                nav {
                    width: 100%;
                    min-width: 100%;
                    margin-right: 0px;
                    margin-bottom: 1rem;
                    .experience-button-container {
                        display: flex;

                        button {
                            font-size: 1.5rem;
                            border: 0;
                            outline: 0;
                            background: $primary;
                            color: $white;
                            border-radius: $border-radius;
                            width: 40px;
                            height: 40px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }

                        span {
                            font-size: 1.5rem;
                        }
                    }

                    ul {
                        display: none;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 550px) {
    .work {
        .work-content {
            h1 {
                font-size: 2rem;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .work {
        .work-content {
            h1 {
                font-size: 2rem;
            }

            .experience__details {
                nav {
                    .experience-button-container {}

                    ul {}
                }

                .jobs-container {
                    .job-container {
                        .experience {
                            flex-direction: column;
                            .highlight-container {

                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 400px) {
    .work {
        .work-content {
            h1 {
                font-size: 2rem;
            }

            .experience__details {
                nav {
                    .experience-button-container {}

                    ul {}
                }

                .jobs-container {
                    .job-container {
                        .experience {
                            flex-direction: column;
                            font-size: 1.5rem !important;
                            .highlight-container {

                            }
                        }
                    }
                }
            }
        }
    }
}