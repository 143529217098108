.toolkit {
    margin: 100px 0px;
    .toolkit-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        color: $white;
        padding: 0rem 1rem;

        h1 {
            margin-bottom: 2rem;
            font-size: 2.5rem;
            text-transform: uppercase;
            color: transparent;
            -webkit-text-stroke: .75px $white;
            -webkit-text-fill-color:transparent;
            position: relative;
            left: 100px;
            opacity: 0;
            transition: 1s;
        }

        .icon-row {
            position: relative;
            background: $background;
            padding: 1.5rem;
            border-radius: $border-radius;
            border: 1px solid $white;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            opacity: 0;
            top: 50px !important;
            transition: .3s;

            .icon {
                width: 20%;
                text-align: center;
                padding: 25px 0rem;
                margin-bottom: 0rem;
                opacity: 0;
                top: 20px;
                transition: .3s;

                img {
                    object-fit: contain;
                    height: 50px;
                    width: 50px;
                    filter: grayscale(100%) brightness(150%);
                }

                figcaption {
                    margin-top: 1rem;
                    font-size: 1.25rem;
                    margin-bottom: 0rem;
                }

                &.visible {
                    top: 0px !important;
                    opacity: 1 !important;
                }

                @for $i from 0 through 20 {
                    &:nth-child(#{$i + 1}) {
                      transition-delay: 0.05s * $i;
                    }
                }
            }

            .html,
            .css {
                filter: grayscale(100%) brightness(175%);
            }

            .sass {
                filter: grayscale(100%) brightness(150%);
            }

            .react {
                filter: grayscale(100%) brightness(175%);
            }

            .pg {
                filter: grayscale(100%) brightness(125%);
            }
            .gql {
                filter: grayscale(100%) brightness(250%);
            }
            .aws {
                filter: grayscale(100%) brightness(100%);
                img {
                    transform: scaleX(-1);
                }
            }

            .heroku {
                filter: grayscale(100%) brightness(115%);
            }

            .github {
                filter: grayscale(100%) brightness(750%);
            }

            .git {
                filter: grayscale(100%) brightness(150%);
            }

            .figma {
                filter: grayscale(100%) brightness(125%);
            }

            .vscode {
                filter: grayscale(100%) brightness(125%);
            }

            &.visible {
                top: 0px !important;
                opacity: 1;
            }
        }

    }
}

@media screen and (max-width: 670px) {
    .toolkit {
        .toolkit-content {
            .icon-row {
                .icon {
                    width: 26%;
                }
            }
        }
    }
}

@media screen and (max-width: 550px) {
    .toolkit {
        .toolkit-content {
            h1 {
                font-size: 2rem;
            }
        }
    }
}

@media screen and (max-width: 515px) {
    .toolkit {
        .toolkit-content {
            .icon-row {
                .icon {
                    width: 35%;
                }
            }
        }
    }
}