.footer {
    background: $background;
    position: relative;
    overflow: hidden;

    .layer-1,
    .layer-2,
    .layer-3 {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 100%;
        left: 0;
        bottom: auto;
        right: auto;
        background: $background;
        transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
        z-index: 5;

        &.active {
            top: -100% !important;
        }
    }

    .layer-1 {
        top: 0%;
        background: $background;
    }

    .layer-2 {
        background: $secondary-d;
        transition-delay: .05s;
    }

    .layer-3 {
        background: $primary-d;
        transition-delay: .1s;
    }

    .footer-content {
        background: $background;
        color: $white;
        padding: 1rem;

        .split-content {
            display: flex;
            margin-bottom: 75px;
            .left {
                width: 70%;
                // float: left;
                h1 {
                    margin-bottom: .5rem;
                    font-size: 2rem;
                    text-transform: uppercase;
                    color: transparent;
                    -webkit-text-stroke: .75px $white;
                    -webkit-text-fill-color:transparent;
                }
                
                h2 {
                    font-size: 1.75rem;
                }
        
                .connect-links {
                    .link {
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 1rem;
                        
                        span {
                            font-size: 1.25rem;
                            margin-bottom: -5px;
                        }
        
                        a {
                            position: relative;
                            display: inline;
                            width: 100%;
                            color: $primary;
                            text-decoration: none;
                            font-size: 1.5rem;

                            &:hover,
                            &:focus,
                            &:focus-within {
                                color: $primary !important;
                                border: none;
                                outline: none;
                            }

                            &:before{
                                content: '';
                                position: absolute;
                                bottom: -5px;
                                right: 0;
                                width: 0;
                                height: 3px;
                                background-color: $primary;
                                transition: width 0.5s cubic-bezier(0.25, 1, 0.5, 1);
                            }

                            &:hover:before,
                            &:focus:before,
                            &:focus-within:before {
                                left: 0;
                                right: auto;
                                width: 100%;
                            }
                        }
                    }
                }
            }
    
            .right {
                width: 30%;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: flex-start;
    
                ul {
                    padding: 0px;
                    list-style: none;
                    margin: 0;
                    
                    li {
                        a {
                            display: inline-block;
                            position: relative;
                            font-size: 1.5rem;
                            color: $white;
                            padding: 0rem;
                            margin-bottom: .5rem;

                            &:hover,
                            &:focus,
                            &:focus-within {
                                color: $primary !important;
                                border: none;
                                outline: none;
                            }

                            &:before{
                                content: '';
                                position: absolute;
                                bottom: -5px;
                                right: 0;
                                width: 0;
                                height: 3px;
                                background-color: $primary;
                                transition: width 0.5s cubic-bezier(0.25, 1, 0.5, 1);
                            }

                            &:hover:before,
                            &:focus:before,
                            &:focus-within:before {
                                left: 0;
                                right: auto;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }

        .last-thoughts {
            .lt-container {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                opacity: .75;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .footer {
        .footer-content {
            .split-content {
                .left {
                    h2 {
                        font-size: 1.5rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .footer {
        .footer-content {
            .split-content {
                display: block;
                .left {
                    width: 100% !important;
                    padding-bottom: 1rem;
                    margin-bottom: 1rem;
                    border-bottom: 1px solid #eeeeee5b;
                    h2 {
                        font-size: 1.5rem !important;
                    }
                }

                .right {
                    width: 100%;
                    align-items: flex-start;
                     ul {
                         li {
                             a {
                                 padding: 0rem;
                             }
                         }
                     }
                }
            }
        }
    }
}

@media screen and (max-width: 515px) {
    .footer {
        .footer-content {
            .split-content {
                display: block;
                .left {
                    h1 {
                        font-size: 1.75rem;
                    }

                    h2 {
                        display: none;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 395px) {
    .footer {
        .footer-content {
            .last-thoughts {
                .lt-container {
                    flex-direction: column;
                    align-items: flex-start;
                }
            }
        }
    }
}