.repos {
    margin: 100px 0px;
    .repos-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        color: $white;

        .title {
            margin-bottom: 2rem;
            font-size: 2.5rem;
            text-transform: uppercase;
            color: transparent;
            -webkit-text-stroke: .75px $white;
            -webkit-text-fill-color:transparent;
            position: relative;
            left: 100px;
            opacity: 0;
            transition: 1s;
        }

        .repo-row {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;

            .project-card-container {
                width: 32%;
                // margin: 0 auto;

                .project-card {
                    background: $background;
                    padding: 1.5rem;
                    border-radius: $border-radius;
                    border: 1px solid $white;
                    box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.25);
                    margin-bottom: 50px;
                    transition: .5s;
                    position: relative;
                    opacity: 0;
                    top: 50px;

                    .head {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        span {
                            padding: .375rem;
                            border-radius: $border-radius;
                            background: $secondary;
                        }

                        i,
                        a i {
                            font-size: 2rem;
                            color: $white;
                        }

                        a {
                            transition: .3s;
                            &:hover,
                            &:focus {
                                i {
                                    transition: .3s;
                                    color: darken($primary, 10%) !important;
                                }
                            }
                        }
                    }

                    h1 {
                        opacity: 1;
                        margin: 1rem 0rem;
                        font-size: 1.5rem;
                        color: $white;
                        -webkit-text-stroke: 0px $white;
                        -webkit-text-fill-color: $white;
                    }

                    p {
                        margin: 0rem;
                        font-size: 1.15rem;
                    }

                    .foot {
                        ul {
                            list-style: none;
                            display: flex;
                            // justify-content: space-between;
                            flex-wrap: wrap;
                            padding: 0px;
                            margin: 0rem;
                            margin-top: 1rem;

                            li {
                                opacity: .75;
                                margin-right: 10px;
                            }
                        }
                    }

                    &.visible {
                        top: 0px !important;
                        opacity: 1 !important;
                    }
                }
                @for $i from 0 through 20 {
                    &:nth-child(#{$i + 1}) {
                        .project-card {
                            transition-delay: 0.1s * $i;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1050px) {
    .repos {
        .repos-content {
            .repo-row {
                .project-card-container {
                    width: 45%;
                }
            }
        }
    }
}

@media screen and (max-width: 769px) {
    .repos {
        .repos-content {
            .repo-row {
                .project-card-container {
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 550px) {
    .repos {
        .repos-content {
            .title {
                font-size: 2rem;
            }
        }
    }
}