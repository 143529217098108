$background: #202028;
$background-l: lighten(#202028, 10%);
$white: #EEEEEE;
$primary: #BA68C8;
$primary-d: darken(#BA68C8, 10%);
$secondary: #6D6DD6;
$secondary-d: darken(#6D6DD6, 10%);
$border-radius:  5px;
// $splash-gradient: linear-gradient(90deg, #C72BD3, #A638CE, #c244c9, #A638CE, #C72BD3, #A638CE);
$splash-gradient: linear-gradient(90deg, #BA68C8, #a23ab4, #d067e2, #a23ab4, #BA68C8, #BA68C8);

.app {
  font-family: 'Oswald', sans-serif;
  min-width: 100vw;
  max-width: 100vw;
  overflow-x: hidden;
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  position: relative;
  background: $background;

  .textured-overlay {
    position: absolute;
    width: 100vw;
    height: 100%;
    background: url('./assets/shattered-dark.png');
    opacity: .2;
  }

  .splash,
  .about,
  .work,
  .toolkit,
  .repos,
  .footer {
    position: relative;
    z-index: 2;
    padding: 1rem;
  }
}

body, main {
  overflow-x: hidden;
  width: 100vw;
  max-width: 100vw;
  min-width: 100vw;
}

@import "./styles/Nav.scss";
@import "./styles/Splash.scss";
@import "./styles/About.scss";
@import "./styles/Work.scss";
@import "./styles/Toolkit.scss";
@import "./styles/Repos.scss";
@import "./styles/Footer.scss";
@import "./styles/Interactions.scss";