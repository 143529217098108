.about {
    margin: 100px 0px;
    .about-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        color: $white;

        h1 {
            margin-bottom: 2rem;
            font-size: 2.5rem;
            text-transform: uppercase;
            color: transparent;
            -webkit-text-stroke: .75px $white;
            -webkit-text-fill-color:transparent;
            position: relative;
            left: 100px;
            opacity: 0;
            transition: 1s;

            &.active {
                left: 0;
                opacity: 1;
            }
        }
        .about-text {
            width: 100%;
            background: $background;
            padding: 1.5rem;
            border-radius: $border-radius;
            border: 1px solid $white;
            position: relative;
            opacity: 0 !important;
            top: 100px;
            transition: 1s;
            transition-delay: .3s;
    
            p {
                font-size: 1.25rem;

                &:last-of-type {
                    margin-bottom: 0rem !important;
                }
            }
    
            .list-intro {
                margin-bottom: .5rem;
            }
    
            ul {
                display: grid;
                grid-template-columns: repeat(2, minmax(250px, 200px));
                gap: 0px 10px;
    
                li {
                    color: $primary;
                    span {
                        color: $white;
                        font-size: 1.25rem;
    
                        span {
                            font-size: 1rem;
                            opacity: .75;
                        }
                    }
                }
            }

            &.active {
                opacity: 1;
                top: 0px;
            }
        }

    }
}

@media screen and (max-width: 768px) {
    .about {
        .about-content {
            .about-text {
                ul {
                    li {
                        span {
                            span {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 550px) {
    .about {
        .about-content {
            h1 {
                font-size: 2rem;
            }
        }
    }
}

@media screen and (max-width: 475px) {
    .about {
        .about-content {
            .about-text {
                ul {
                    grid-template-columns: repeat(2, minmax(150px, 200px));
                }
            }
        }
    }
}

@media screen and (max-width: 375px) {
    .about {
        .about-content {
            .about-text {
                ul {
                    grid-template-columns: repeat(1, minmax(150px, 200px));
                }
            }
        }
    }
}